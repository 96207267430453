<template>
  <div class="lists-no">
    <div class="no-data">
      <img src="~@/assets/images/balance/no-data.gif" :style="{height: noHeight,width: noWidth }">
    </div>
  </div>
</template>
<script>
export default {
  name: "noData",
  props: {
    noWidth: {
      type: String,
      default: '364px'
    },
    noHeight: {
      type: String,
      default: '340px'
    },
  },
  data() {
    return{
    }
  },
}
</script>
<style lang="scss" scoped>
  .lists-no{
    width: 100%;
    height: 658px;
    background: #F7F9FC;
    position: relative;
    .no-data{
      text-align: center;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: 3;
      >img{
        width: 364px;
        height: 340px;
      }
    }

  }
</style>