import http from './fetch.js';

export function getCategoryList(params = {}) {
    return http({
        url: '/web/client/firstList',
        method: 'get',
        params: params
    });
}

export function getSubCategoryList(params = {}) {
    return http({
        url: '/web/client/secondList',
        method: 'get',
        params: params
    });
}

export function getCouponList(params = {}) {
    return http({
        url: '/web/client/shopCouponList',
        method: 'post',
        params: params
    });
}

export function refreshGoodsOrder(params = {}) {
  return http({
      url: '/web/client/refreshOrder',
      method: 'post',
      params: params
  });
}

export function getGoodsList(params = {}) {
    return http({
        url: '/web/client/goodsList',
        method: 'get',
        params: params
    });
}

export function createGoodsOrder(params = {}) {
    return http({
        url: '/web/client/createClientOrder',
        method: 'get',
        params: params
    });
}

export function payOrder(params = {}) {
    return http({
        url: '/web/client/clientPay',
        method: 'get',
        params: params
    });
}

export function payStatus(params = {}) {
  return http({
      url: '/web/client/payStatus',
      method: 'get',
      params: params
  });
}

export function goodsTaste(params = {}) {
    return http({
        url: '/web/client/goodsTaste',
        method: 'get',
        params: params
    });
}
export function goodsOrderFailure(params = {}) {
    return http({
        url: '/web/client/goodsOrderFailure',
        method: 'get',
        params: params
    });
}
