import http from './fetch.js';

export function getUserInfo(params = {}) {
  return http({
    url: '/web/client/user/getUserInfo',
    method: 'get',
    params: params
  });
}
export function getProfession(params = {}) {
  return http({
    url: '/web/client/user/getProfession',
    method: 'get',
    params: params
  });
}
export function getTag(params = {}) {
  return http({
    url: '/web/client/user/getTag',
    method: 'get',
    params: params
  });
}
export function uploadPhotos(params = {}) {
  return http({
    url: '/web/client/user/uploadPhotos',
    method: 'post',
    params: params
  });
}
export function editUserInfo(params = {}) {
  return http({
    url: '/web/client/user/editUserInfo',
    method: 'get',
    params: params
  });
}
// 设置中心-查询余额、收入
export function getWalletInfo(params = {}) {
  return http({
    url: '/web/client/user/getWalletInfo',
    method: 'get',
    params: params
  });
}
// 收入支出订单

export function getLgCoinOrderList(params = {}) {
  return http({
    url: '/web/client/user/getLgCoinOrderList',
    method: 'get',
    params: params
  });
}
// 相册列表
export function getUserPhotos(params = {}) {
  return http({
    url: '/web/client/user/getUserPhotos',
    method: 'get',
    params: params
  });
}
// 删除相册
export function deletePhotos(params = {}) {
  return http({
    url: '/web/client/user/deletePhotos',
    method: 'get',
    params: params
  });
} 
// 获取验证码 
export function getVerificationCode(params = {}) {
  return http({
    url: '/web/client/user/getVerificationCode',
    method: 'get',
    params: params
  });
}
// 修改密码
export function updatePasswd(params = {}) {
  return http({
    url: '/web/client/user/updatePasswd',
    method: 'get',
    params: params
  });
}

// 陪玩订单-我的接单列表
export function getOrders(params = {}) {
  return http({
    url: '/web/client/play/getOrders',
    method: 'get',
    params: params
  });
}
// 陪玩订单-我的下单列表
export function getHistoryOrder(params = {}) {
  return http({
    url: '/web/client/play/getHistoryOrder',
    method: 'get',
    params: params
  });
}
 
//申请入驻
export function playerApply(params = {}) {
  return http({
    url: '/web/client/play/playerApply',
    method: 'post',
    params: params
  });
}
// 接单不接单   replyOrder 1不接 2接
export function replyOrder(params = {}) {
  return http({
    url: '/web/client/play/replyOrder',
    method: 'get',
    params: params
  });
}
// 查询服务
export function getGameService(params = {}) {
  return http({
    url: '/web/client/play/getGameService',
    method: 'get',
    params: params
  });
}
// 查询游戏
export function getGame(params = {}) {
  return http({
    url: '/web/client/play/getGame',
    method: 'get',
    params: params
  });
}
// 添加服务
export function addGameService(params = {}) {
  return http({
    url: '/web/client/play/addGameService',
    method: 'post',
    params: params
  });
}
// 修改服务
export function updateGameService(params = {}) {
  return http({
    url: '/web/client/play/updateGameService',
    method: 'get',
    params: params
  });
}
// 上线、下线服务

export function online(params = {}) {
  return http({
    url: '/web/client/play/online',
    method: 'get',
    params: params
  });
}
// 删除服务
export function deleteGameService(params = {}) {
  console.log(params)
  return http({
    url: '/web/client/play/deleteGameService',
    method: 'get',
    params: params
  });
}

// 查询服务详情

export function getGameServiceInfo(params = {}) {
  console.log(params)
  return http({
    url: '/web/client/play/getGameServiceInfo',
    method: 'get',
    params: params
  });
}
// 查询记录
export function getServiceApplyLog(params = {}) {
  console.log(params)
  return http({
    url: '/web/client/play/getServiceApplyLog',
    method: 'get',
    params: params
  });
}
// 充值霖感币
export function lgCoinOrder(params = {}) {
  console.log(params)
  return http({
    url: '/web/client/user/lgCoinOrder',
    method: 'get',
    params: params
  });
}

// 查询地区
export function getProvince(params = {}) {
  console.log(params)
  return http({
    url: '/web/client/user/getProvince',
    method: 'get',
    params: params
  });
}
// 设为背景
export function setBackdrop(params = {}) {
  console.log(params)
  return http({
    url: '/web/client/play/setBackdrop',
    method: 'get',
    params: params
  });
}
// 取消 完成订单
export function completeOrder(params = {}) {
  console.log(params)
  return http({
    url: '/web/client/play/completeOrder',
    method: 'get',
    params: params
  });
}
// 查询记账本
export function getPocketBook(params = {}) {
  console.log(params)
  return http({
    url: '/web/client/user/getPocketBook',
    method: 'get',
    params: params
  });
}
// 查询是否在线
export function getOnline(params = {}) {
  console.log(params)
  return http({
    url: '/web/client/play/getOnline',
    method: 'get',
    params: params
  });
}

// 修改手机号
export function updatePhone(params = {}) {
  console.log(params)
  return http({
    url: '/web/client/user/updatePhone',
    method: 'get',
    params: params
  });
}
// 轮训查询订单
export function getLgCoinOrder(params = {}) {
  console.log(params)
  return http({
    url: '/web/client/user/getLgCoinOrder',
    method: 'get',
    params: params
  });
}
// 验证旧手机号
export function checkPhone(params = {}) {
  console.log(params)
  return http({
    url: '/web/client/user/checkPhone',
    method: 'get',
    params: params
  });
}

// 打赏提取
export function dynamicSettle(params = {}) {
  return http({
    url: '/web/client/dynamic/dynamicSettle',
    method: 'get',
    params: params
  });
}

// 打赏提取
export function activityData(params = {}) {
  return http({
    url: '/web/client/user/activity',
    method: 'get',
    params: params
  });
}



// 充值网费-创建订单
export function clientRecharge(params = {}) {
  return http({
    url: '/web/client/user/clientRecharge',
    method: 'get',
    params: params
  });
}
// 充值网费-支付订单
export function clientPay(params = {}) {
  return http({
    url: '/web/client/clientPay',
    method: 'get',
    params: params
  });
}
// 充值网费-轮询状态
export function rechargePayStatus(params = {}) {
  return http({
    url: '/web/client/rechargePayStatus',
    method: 'get',
    params: params
  });
}




