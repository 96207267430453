import http from '../fetch.js';
// 动态列表
export function squareList(params = {}) {
  return http({
    url: '/web/client/dynamic/list',
    method: 'get',
    params: params
  });
}
export function rankingList(params = {}) {
  return http({
    url: '/web/client/topic/rankingList',
    method: 'get',
    params: params
  });
}
 // 话题标签一级
export function topicFirstList(params = {}) {
  return http({
    url: '/web/client/topic/topicFirstList',
    method: 'get',
    params: params
  });
}
// 二级话题列表
export function topicList(params = {}) {
  return http({
    url: '/web/client/topic/topicList',
    method: 'get',
    params: params
  });
}
// 点赞
export function giveLike(params = {}) {
  return http({
    url: '/web/client/dynamic/giveLike',
    method: 'post',
    params: params
  });
}
//  发布动态
export function publish(params = {}) {
  return http({
    url: '/web/client/dynamic/publish',
    method: 'post',
    params: params
  });
}
//  发布动动态详情
export function squareInfo(params = {}) {
  return http({
    url: '/web/client/dynamic/info',
    method: 'get',
    params: params
  });
}
// 一级评论列表
export function commentList(params = {}) {
  return http({
    url: '/web/client/dynamic/commentList',
    method: 'get',
    params: params
  });
}
// 二级评论回复列表
export function twoList(params = {}) {
  return http({
    url: '/web/client/comment/list',
    method: 'get',
    params: params
  });
}
// 评论
export function commentMessage(params = {}) {
  return http({
    url: '/web/client/comment/comment',
    method: 'post',
    params: params
  });
}
// 删除图片
export function deleteDynamicImg(params = {}) {
  return http({
    url: '/web/client/dynamic/deleteDynamicImg',
    method: 'post',
    params: params
  });
}

//打赏
export function giveReward(params = {}) {
  return http({
    url: '/web/client/dynamic/giveReward',
    method: 'post',
    params: params
  });
}
export function dynamicDelete(params = {}) {
  return http({
    url: '/web/client/dynamic/delete',
    method: 'get',
    params: params
  });
}



