import cryptoJs from 'crypto-js'

var key = 'LG66DJ88'; //密钥
var iv = [2, 0, 2, 0, 1, 2, 2, 5];

function byteToString(arr) {
  if(typeof arr === 'string') {
    return arr;
  }
  var str = '',
    _arr = arr;
  for(var i = 0; i < _arr.length; i++) {
    var one = _arr[i].toString(2),
      v = one.match(/^1+?(?=0)/);
    if(v && one.length == 8) {
      var bytesLength = v[0].length;
      var store = _arr[i].toString(2).slice(7 - bytesLength);
      for(var st = 1; st < bytesLength; st++) {
        store += _arr[st + i].toString(2).slice(2);
      }
      str += String.fromCharCode(parseInt(store, 2));
      i += bytesLength - 1;
    } else {
      str += String.fromCharCode(_arr[i]);
    }
  }
  return str;
}
//CBC模式加密
export const encryptByDESModeCBCUtf8to64 = (message) => {
  var ivString = byteToString(iv)
  var keyHex = cryptoJs.enc.Utf8.parse(key);
  var ivHex = cryptoJs.enc.Utf8.parse(ivString);
  var encrypted = cryptoJs.DES.encrypt(message, keyHex, {
    iv: ivHex,
    mode: cryptoJs.mode.CBC,
    padding: cryptoJs.pad.Pkcs7
  })
  return encrypted.ciphertext.toString(cryptoJs.enc.Base64);
}

//CBC模式解密
export const decryptByDESModeCBC = (ciphertext2) => {
  var ivString = byteToString(iv)
  var keyHex = cryptoJs.enc.Utf8.parse(key);
  var ivHex = cryptoJs.enc.Utf8.parse(ivString);
  var decrypted = cryptoJs.DES.decrypt({
    ciphertext: cryptoJs.enc.Base64.parse(ciphertext2)
  }, keyHex, {
    iv:ivHex,
    mode: cryptoJs.mode.CBC,
    padding: cryptoJs.pad.Pkcs7
  });
  return decrypted.toString(cryptoJs.enc.Utf8);
}
export const imageToBase64 = (img) => {
// Vue.prototype.$imageToBase64 = img => { //定义全局
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, img.width, img.height);
    var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
    var dataURL = canvas.toDataURL("image/jpeg" + ext);
    return dataURL;
};
export const base64ToFile = (urlData, fileName) => {
  let arr = urlData.split(',');
  let mime = arr[0].match(/:(.*?);/)[1];
  let bytes = atob(arr[1]); // 解码base64
  let n = bytes.length
  let ia = new Uint8Array(n);
  while (n--) {
      ia[n] = bytes.charCodeAt(n);
  }
  return new File([ia], fileName, { type: mime });
}
export const getAstro = (m,d) =>{
  return "魔羯水瓶双鱼牡羊金牛双子巨蟹狮子处女天秤天蝎射手魔羯".substr(m*2-(d<"102223444433".charAt(m-1)- -19)*2,2);
}
