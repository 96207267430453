import http from './fetch.js';

export function guessingList(params = {}) {
    return http({
        url: '/web/client/guessingList',
        method: 'get',
        params: params
    });
}
export function onlineRecord(params = {}) {
    return http({
        url: '/web/client/onlineRecord',
        method: 'get',
        params: params
    });
}
export function rechargeList(params = {}) {
    return http({
        url: '/web/client/rechargeList',
        method: 'get',
        params: params
    });
}
export function userinfo(params = {}) {
    return http({
        url: '/web/info',
        method: 'get',
        params: params
    });
}

export function guessList(params = {}) {
    return http({
        url: '/web/client/guess/list',
        method: 'get',
        params: params
    });
}
export function betList(params = {}) {
    return http({
        url: '/web/client/bet/list',
        method: 'get',
        params: params
    });
}
export function guessBet(params = {}) {
    return http({
        url: '/web/client/guess/bet',
        method: 'get',
        params: params
    });
}
// 呼叫网管
export function callNetwork(params = {}) {
    return http({
        url: '/web/client/callNetwork',
        method: 'get',
        params: params
    });
}
// export function memberBalance(params = {}) {
//   return http({
//       url: '/web/client/balance',
//       method: 'get',
//       params: params
//   });
// }

// 判断是否显示签的按钮   
export function signInStatus(params = {}) {
    return http({
        url: '/web/client/signInStatus',
        method: 'get',
        params: params
    });
}

