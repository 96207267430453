import http from '../fetch.js';
// 答题列表
export function getTickets(params = {}) {
  return http({
    url: '/web/client/answer/getTickets',
    method: 'get',
    params: params
  });
}
// 购买门票
export function buyTicket(params = {}) {
  return http({
    url: '/web/client/answer/buyTicket',
    method: 'get',
    params: params
  });
}
// 提交答题卡
export function submitAnswerSheet(params = {}) {
  return http({
    url: '/web/client/answer/submitAnswerSheet',
    method: 'get',
    params: params
  });
}



