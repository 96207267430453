<template>
  <div class="popup">
    <div class="popup-tip-bg"  v-show="maskHide"></div>
    <div class="UIdialog">
      <div class="tip-close" @click="maskHandle" v-show="maskHide">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="UIdialog_hd"><strong class="UIdialogTitle">{{title}}</strong></div>
      <div class="UIdialog_bd">
        <slot>弹窗内容</slot>
      </div>
      <div class="UIdialog_ft">
        <div v-if="cancel" class="left" @click="onCancel" >{{cancelVal}}</div>
        <div v-if="confirm" class="right " :class={UIdialog_btn_disabled:isDisabled} @click="onConfirm">{{confirmVal}}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'jlDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    confirm: {
      type: Boolean,
      default: false
    },
    cancel: {
      type: Boolean,
      default: false
    },
    title: {
      default: null
    },
    confirmVal: {
      type: String,
      default: '是'
    },
    cancelVal: {
      type: String,
      default: '否'
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    maskHide:{
      type: Boolean,
      default:true
    }
  },
  methods: {
    handleHeadCloseData() {

    },
    maskHandle(){
      if(this.maskHide){
        this.$emit('input',false)
      }
    },
    onConfirm(){
      if(!this.isDisabled){
        this.$emit('onConfirm')
        this.$emit('input', false)
      }
    },
    onCancel(){
      this.$emit('onCancel')
      this.$emit('input', false)
    }
  }
}
</script>
<style lang="less" scoped>
  .popup-tip-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 22;

  }
  .UIdialog{
    position: fixed;
    // width: 345px;
    // height: 563px;
    padding: 30px 46px;
    top: 55%;
    left: 50%;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 13px 31px 0px rgba(204, 219, 230, 0.35);
    transform: translate(-50%,-50%);
    z-index: 33;
    .tip-close{   
      position: absolute;
      width: 40px;
      height: 40px;
      top: -26px;
      right: -20px;
      z-index: 44;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .tip-title{
      margin-top: 30px;
      color: #1A1A1A;
      font-size: 16px;
      font-weight: bold;
    }
    .tip-bg{
      width: 100%;
      height: 346px;
      background: #DCE5EE;
      border: 6px;
      margin-top: 38px;
      .bg-show{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: #E7EEF6;
      }
    }
    .UIdialog_ft{
      display: flex;
      margin-top: 54px;
      >div{
        width: 88px;
        height: 38px;
        border-radius: 5px;
        color: #fff;
        font-size: 14px;
        line-height: 38px;
        text-align: center;
        cursor: pointer;
      }
      .left{
        background: #5ED6FC;
        box-shadow: 0px 8px 19px 0px rgba(94, 214, 252, 0.3);
      }
      .right{
        margin-left: 23px;
        background: #5564F3;
        box-shadow: 0px 8px 19px 0px rgba(85, 100, 243, 0.3);
      }
    }
  }
</style>
