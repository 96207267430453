import http from '../fetch.js';

export function slideshow(params = {}) {
  return http({
    url: '/web/client/firstPage/slideshow',
    method: 'get',
    params: params
  });
}
export function shopInfo(params = {}) {
  return http({
    url: '/web/client/shopInfo',
    method: 'get',
    params: params
  });
}
// 充值排行 50名
export function rechargeRanking(params = {}) {
  return http({
    url: '/web/client/rechargeRanking',
    method: 'get',
    params: params
  });
}
// 我的排名
export function myRechargeRanking(params = {}) {
  return http({
    url: '/web/client/myRechargeRanking',
    method: 'get',
    params: params
  });
}
// 招聘-列表
export function bringInList(params = {}) {
  return http({
    url: '/web/client/bringInList',
    method: 'get',
    params: params
  });
}
// 招聘-详情
export function bringInInfo(params = {}) {
  return http({
    url: '/web/client/bringInInfo',
    method: 'get',
    params: params
  });
}
// 招聘-申请
export function applyFor(params = {}) {
  return http({
    url: '/web/client/applyFor',
    method: 'post',
    params: params
  });
}

// 签到-列表
export function signInList(params = {}) {
  return http({
    url: '/web/client/signInList',
    method: 'get',
    params: params
  });
}
// 签到-提交
export function signIn(params = {}) {
  return http({
    url: '/web/client/signIn',
    method: 'get',
    params: params
  });
}



