import http from '../fetch.js';

// 商城-商品列表
export function getProductList(params = {}) {
    return http({
        url: '/web/client/mall/getProductList',
        method: 'get',
        params: params
    });
}
// 商城-商品详情
export function getProductInfo(params = {}) {
    return http({
        url: '/web/client/mall/getProductInfo',
        method: 'get',
        params: params
    });
}

//商城-订单列表
export function getOrderSummary(params = {}) {
    return http({
        url: '/web/client/mall/getOrderSummary',
        method: 'get',
        params: params
    });
}
//商城-创建订单
export function createOrder(params = {}) {
    return http({
        url: '/web/client/mall/create',
        method: 'get',
        params: params
    });
}
//商城-支付订单
export function orderPay(params = {}) {
    return http({
        url: '/web/client/mall/pay',
        method: 'get',
        params: params
    });
} 
// 商城-支付轮询
export function polling(params = {}) {
    return http({
        url: '/web/client/mall/polling',
        method: 'get',
        params: params
    });
}
// 商城-查询地区
export function getProvince(params = {}) {
    return http({
        url: '/web/client/mall/getProvince',
        method: 'get',
        params: params
    });
}
// 商城-编辑地址
export function editReceiptAddress(params = {}) {
    return http({
        url: '/web/client/mall/editReceiptAddress',
        method: 'get',
        params: params
    });
}
// 商城-新增地址
export function addReceiptAddress(params = {}) {
    return http({
        url: '/web/client/mall/addReceiptAddress',
        method: 'get',
        params: params
    });
}
// 商城-删除地址
export function deleteReceiptAddress(params = {}) {
    return http({
        url: '/web/client/mall/deleteReceiptAddress',
        method: 'get',
        params: params
    });
}
// 商城-确认收货
export function confirmReceipt(params = {}) {
    return http({
        url: '/web/client/mall/confirmReceipt',
        method: 'get',
        params: params
    });
}
// 商城-推荐商品
export function selectRecommend(params = {}) {
    return http({
        url: '/web/client/mall/selectRecommend',
        method: 'get',
        params: params
    });
}
// 浏览记录
// /weapp/mall/notesBrowsing
export function notesBrowsing(params = {}) {
    return http({
        url: '/web/client/mall/notesBrowsing',
        method: 'get',
        params: params
    });
}


