import http from '../fetch.js';

export function getClientPlayerList(params = {}) {
  return http({
    url: '/web/client/play/getClientPlayerList',
    method: 'get',
    params: params
  });
}
export function getPlayerInfo(params = {}) {
  return http({
    url: '/web/client/play/getPlayerInfo',
    method: 'get',
    params: params
  });
}
// 创建订单
export function createOrder(params = {}) {
  return http({
    url: '/web/client/play/createOrder',
    method: 'get',
    params: params
  });
}

// 支付订单
export function payOrder(params = {}) {
  return http({
    url: '/web/client/play/payOrder',
    method: 'get',
    params: params
  });
}
// 轮训下单支付
export function pollingPlayWithOrder(params = {}) {
  console.log(params)
  return http({
    url: '/web/client/play/pollingPlayWithOrder',
    method: 'get',
    params: params
  });
}
