<style lang="less">
@import "./index.less";
</style>
<template>
    <div class="header">
        <div class="header-warp row">
            <img class="logo" src="~@/assets/images/comment/logo.png" alt="">
            <div class="col shopName">电竞蜂门店 - {{info.name}}</div>
            <!-- <div>
                <el-select v-model="shopId" size="mini" placeholder="全部" style="width:252px;">
                    <el-option label="全部" value=""></el-option>
                </el-select>
            </div> -->
        </div>
    </div>
</template>
<script>
import { getShopInfo } from "@/api/comment";
import { encryptByDESModeCBCUtf8to64, decryptByDESModeCBC }  from '@/common/utils/des'

export default {
    name: 'commentHeader',

    data() {
        return {
           secret: '',
           info: {}
        }
    },

    methods: {
        getShopInfo(){
            var query = this.$route.query;
            // query.secret = encryptByDESModeCBCUtf8to64(JSON.stringify({
            //     shopId: 7468,
            //     deviceNumber: 'zdly-001',
            //     d: new Date().getTime()
            // }))
            this.secret = query.secret?decodeURIComponent(query.secret):'';
            getShopInfo({
                secret: this.secret
            }).then(res=>{
                if(res.code == 200){
                    this.info = res.data
                }
            })
        }
    },
    created(){
        this.getShopInfo();
    },
    mounted() {

    },
    destroyed(){

    }
}
</script>
