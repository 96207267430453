const arrRandom = arr =>{
    var index = Math.floor((Math.random() * arr.length)) || 0;
    return arr[index];
}

const phoneVerif = val =>{
    var check = /^[1][3-9]\d{9}$|^([6|9])\d{7}$|^[0][9]\d{8}$|^[6]([8|6])\d{5}$/;
    return !check.test(val);
}

const numberInt = val =>{
    var check = /(^[1-9]\d*$)/;
    return !check.test(val);
}
// !(/(^[1-9]\d*$)/.test(places))
const dateFormat = (date, format) =>{
    
    var dateObj = new Date(date);
    var o = {
        "y+" : dateObj.getFullYear(), //month
        "M+" : dateObj.getMonth()+1, //month
        "d+" : dateObj.getDate(),    //day
        "h+" : dateObj.getHours(),   //hour
        "m+" : dateObj.getMinutes(), //minute
        "s+" : dateObj.getSeconds(), //second
        "q+" : Math.floor((dateObj.getMonth()+3)/3),  //quarter
        "S" : dateObj.getMilliseconds() //millisecond
    };

    if(/(y+)/.test(format)) format=format.replace(RegExp.$1,
    (dateObj.getFullYear()+"").substr(4 - RegExp.$1.length));
    for(var k in o){
        if(new RegExp("("+ k +")").test(format))
            format = format.replace(RegExp.$1,RegExp.$1.length==1 ? o[k] :("00"+ o[k]).substr((""+ o[k]).length));
    }
    return format;
}
const transDate =(strtime)=> { 
    var date = new Date(strtime.replace(/-/g, '/'));
    date=Date.parse(date);
    var tt = new Date(parseInt(date));
    var days = parseInt((new Date().getTime() - date) / 86400000);
    var today = new Date().getDate();
    var year = tt.getFullYear();
    var mouth = tt.getMonth() + 1;
    var day = tt.getDate();
    var time = tt.getHours() < 10 ? "0" + tt.getHours() : tt.getHours();
    var min = tt.getMinutes() < 10 ? "0" + tt.getMinutes() : tt.getMinutes();
    var result, offset;
　　　　　　　offset = Math.abs(today - day);
　　　　if (days < 2&&offset<2) {
　　　　　　　if (offset === 0) {
            result =  time + ":" + min;
        } else if (offset === 1) {
            result = "昨天"
        }
    } else {
        result = year + "-" + mouth + "-" + day 
    }
    return result;
}
const publicMethod = { 
 
  getTimestamp(time) { //把时间日期转成时间戳
   return (new Date(time)).getTime() / 1000
  }
 
}
module.exports = {
    arrRandom,
    phoneVerif,
    dateFormat,
    publicMethod,
    numberInt,
    transDate
}
