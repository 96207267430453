<template>
  <div class="messaging">
    <div class="messageing-tip-bg" v-show="messageingShow"></div>
    <div class="messageing-tip" v-show="messageingShow">
      <div class="tip-close" @click="handleMessageingClose"  style="-webkit-app-region: no-drag;">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="messaging-left" >
        <div class="messaging-title">
          <div class="title-left">消息列表（{{listData.length}}）</div>
          <div class="title-right" :class="{'title-right-show': deleteShow == true} " @click="handleDelete">
            <img src="~@/assets/images/balance/first/delete.png">
          </div>
        </div>
        <ul class="left-list">
          <li :class="{'li-Show': item.userID == addForm.toUserID}"  v-for="(item,idx) in listData" :key="idx" @click="handleMessageOpen(item,idx)" >
            <div class="list-all">
              <div class="all-close" v-if="deleteShow && item.userID == addForm.toUserID" @click.stop="handleDeleteListData(item,idx)"> <i class="el-icon-close"></i></div>
              <div class="list-head">
                <img :src="item.headImg?item.headImg:'https://img.lingane-sport.com/headImg.jpg' " />
                <div class="head-show" v-if="item.statusShow"></div>
              </div>
              <div class="list-text">
                <div class="text-flex">
                  <div class="flex-left">
                    {{item.nickName?item.nickName:'陌生人'}}
                  </div >
                  <div class="flex-right">
                    {{item.showTime}}
                  </div>

                </div>
                <div class="text-time">
                  {{item.text}}
                </div>

              </div>

            </div>
          </li>
        </ul>
      </div>
      <div class="messaging-right">
        <div class="right-title">{{titleName}}</div>
        <div class="right-content" :class="{'right-content-show':footStatus == true}" id="msgText" ref="scrollBox" @scroll="scrollEvent " >
          <div class="" v-for="(item,idx) in messageList" :key="idx">
            <div class="content-other" v-if="!item.myStatus&& !item.isRevoked">
              <div class="other-head">
                <img :src="item.headImg"/>
              </div>
              <div class="other-right">
                <div class="other-name">
                  {{item.name}} <span> {{item.time}}</span>
                </div>
                <div class="other-text" > 
                  <div class="text-right" v-if="item.text"  v-html="item.text.replace(/(\r\n|\n|\r)/gm,'<br />')"></div>
                  <div class="text-right-img" v-else  @click="handleLookImg(item.img)">
                    <img :src="item.img"/>
                  </div>
                  <div class="text-left"></div>
                
                </div>

              </div>
            </div>
            <div class="content-my"  v-if="item.myStatus && !item.isDeleted && !item.isRevoked">
              <div class="other-right" >
                <div class="other-name">
                  <span>{{item.time}} </span> {{item.name}}
                </div>
                <div   class="other-text" v-if="!item.isRevoked"> 
                  <div class="text-left"></div>
                
                  <div class="text-right" v-if="item.text" v-html="item.text.replace(/(\r\n|\n|\r)/gm,'<br />')" @contextmenu.prevent="rightShow(item,idx)"></div>
                  <div class="text-right-img" v-else  @click="handleLookImg(item.img)">
                    <img :src="item.img" @contextmenu.prevent="rightShow(item,idx)"/>
                  </div>
                  <div class="flex-tip" :class="{'last-tip':idx == messageList.length-1}" v-if="item.selectd && idx == messageIdx">
                    <div  @click.stop="handleRevocation(item,idx)" v-if="nowTime<item.isRevokedTime">撤回</div>
                    <div v-else @click.stop="handleDeleteMessageList(item,idx)">删除</div>
                  </div>
                </div>
              </div>
              <div class="other-head">
                <img :src="item.headImg" />
              </div>
            </div>
            <div class="item-isRevoked" v-if=" item.isRevoked" >
               {{item.myStatus?'你':item.nickName}}撤回了一条信息 <span class="" v-if="nowTime<item.isRevokedTime && item.myStatus && item.text" @click="handleEditMessage(item)"> 重新编辑</span>
            </div>

          </div>
        </div>
        <div class="right-foot" v-if="!footStatus">
          <div class="foot-input">
            <el-input
            resize="none"
            type="textarea"
            @keydown.native="keydown"
            :rows="2"
            placeholder="说点什么..."
            v-model="addForm.content">
          </el-input>
          </div>
          <div class="foot-img" @click="handleImgAdd">
            <input @change="handleFileData" :type="inputType" accept="image/*"
            name="uploader-input" 
            ref="uploader"
            class="uploader-file"
            id="imagePicker">
            <img src="~@/assets/images/balance/first/imgIcon.png"/>
          </div>
          <div class="foot-add" @click="handleTomessage">
            <img src="~@/assets/images/balance/first/add.png"/>
          </div>
        </div>
      </div>
    </div>
    <div class="look-tip-bg" v-show="lookShow"></div>
    <div class="look-tip" v-show="lookShow">
      <img  class="look-img" :src="lookUrl"/>
      <div class="tip-close" @click="handleLookClose"  style="-webkit-app-region: no-drag;">
        <img  src="~@/assets/images/balance/user/close.png"/>
      </div>
    </div>
  </div>
</template>

<script>

import TIM from 'tim-js-sdk'
import TIMUploadPlugin from 'tim-upload-plugin';
import {
  importUserId,getAppData,updateLgUserInfoToIm ,addGroupMember
} from "@/api/balance/message";
import { encryptByDESModeCBCUtf8to64, decryptByDESModeCBC,getAstro }  from '@/common/utils/des'
export default {
  name: "messaging",
  data() {
    return {
      deleteShow: false,
      listData: [],
      addForm: {
        content: '',
        toUserID: '',
        img:'',
      },
      messageList: [],
      titleName: '',
      listIdx: 0,
      loading: false,
      nextReqMessageID: '',
      innerScrollHeight: 0,
      lastHeight: 0,
      lgUserId: '',
      messageingShow: false,
      appSDKApp:{},
      tim: null,
      toForm:{
        lgUserId: '',
      },
      zdHelper: {},
      zdGuessing: {},
      zdGame: {},
      lookUrl: '',
      lookShow: false,
      firstObj: {},
      inputType: 'file',
      footStatus: false,
      messageIdx: '',
      nowTime: null,
    };
  },
  components: {},
  methods: {
    handleListMessage(item) {
      let _this = this
      let obj = {};
      _this.$store.state.messageShow = true
      let arr = []
      _this.$store.state.messageShow = true
      let srtTime = item.time * 1000
      let time = _this.$utils.dateFormat(srtTime,'yyyy-MM-dd hh:mm:ss') 
      obj.userID = item.from
      if(item.payload.text) {
        if(item.conversationID != 'C2Cadministrator'){
          obj.text = item.payload.text

        }

      }else{
        obj.img=item.payload.imageInfoArray[2].imageUrl
      }
      obj.name = item.nick
      obj.headImg = item.avatar
      obj.time = _this.$utils.transDate(time)  
      obj.selectd = false
      obj.itemObj = item
      obj.isRevoked = item.isRevoked
      obj.isRevokedTime = item.time * 1000+ (60*1000 *2),
      obj.isDeleted = item.isDeleted
      for(let key in _this.listData) {
        if(_this.listData[key].userID == obj.userID) {
          _this.listData[key].text = obj.text
          _this.listData[key].showTime = obj.time
          _this.listData[key].nickName = obj.name
          _this.listData[key].headImg = obj.headImg
          if(_this.addForm.toUserID ==  obj.userID) {
            _this.handleDeadData(_this.listData[key],key)  
          }
        }
      }
      if(_this.addForm.toUserID ==  obj.userID) {
        _this.messageList.push(obj)    
      }
      _this.firstObj = obj
      _this.handleMessageScrollTop() 
      _this.$forceUpdate()
      if(obj.userID == 'LinGan-Dian-Jing-ToBe-Number-Jcb') {
        _this.zdGame.text =  obj.text
        _this.zdGame.showTime =  obj.time
        if(_this.addForm.toUserID ==  obj.userID) {
          _this.handleDeadData(obj,9003)
          obj.headImg = require('@/assets/images/balance/first/message1.png') 
          obj.name = '游戏陪玩'
          _this.messageList.push(obj)
          _this.handleMessageScrollTop() 
        }
      }else  if(obj.userID == 'LinGan-Dian-Jing-ToBe-Number-Pwb') {
        _this.zdGame.text =  obj.text
        _this.zdGame.showTime =  obj.time
        if(_this.addForm.toUserID ==  obj.userID) {
          _this.handleDeadData(obj,9002)
          obj.headImg = require('@/assets/images/balance/first/message3.png') 
          obj.name = '有奖竞猜'
          _this.messageList.push(obj)
          _this.handleMessageScrollTop() 
        }
      }else  if(obj.userID == 'LinGan-Dian-Jing-ToBe-Number-One'){
        _this.zdGame.text =  obj.text
        _this.zdGame.showTime =  obj.time
        if(_this.addForm.toUserID ==  obj.userID) {
          _this.handleDeadData(obj,9001)
          obj.headImg = require('@/assets/images/balance/first/message2.png') 
          obj.name = '炸蛋小助手'
          _this.messageList.push(obj)
          _this.handleMessageScrollTop() 
        }
      }
    },
    handleAddGroupMember() {
      let _this = this
      addGroupMember({fromUserId: sessionStorage.lgUserId,groupId: _this.appSDKApp.groupId}).then(res=>{
        if(res.code == 200) {
        }
      })
    },
    handleImgAdd() {
       this.$refs.uploader.dispatchEvent(new MouseEvent('click')) 
    },
    // 关闭查看大图
    handleLookClose() {
      this.lookShow = false
    },
    // 查看大图
    handleLookImg(url){
      this.lookShow = true
      this.lookUrl = url
    },
    /**
     * 重新编辑
     */
    handleEditMessage(item,idx) {
      this.addForm.content = item.text
    },
    /**
     * 删除消息
     */
    handleDeleteMessageList(item,idx) {
      let _this = this
      let promise = _this.tim.deleteMessage([item.itemObj]);
      promise.then(function(imResponse) {
        // 删除消息成功
        _this.messageList[idx].selectd = false
        _this.messageList[idx].isDeleted  = true
        _this.$forceUpdate()
      }).catch(function(imError) {
        _this.messageList[idx].selectd = false
        // 删除消息失败
        console.warn('deleteMessage error:', imError);
      });
    },
    /**
     * 撤回
     */
    handleRevocation(item,idx) {
      let _this = this
      let promise = _this.tim.revokeMessage(item.itemObj);
      promise.then(function(imResponse) {
        _this.messageList[idx].selectd = false
        _this.messageList[idx].isRevoked = true
        _this.$forceUpdate()
        // 消息撤回成功
      }).catch(function(imError) {
        _this.messageList[idx].selectd = false

        // 消息撤回失败
        console.warn('revokeMessage error:', imError);
      });

    },
    rightShow(item,idx) {
      this.nowTime = new Date().getTime()
      this.messageIdx =  idx
      for(let i in this.messageList) {
        if(idx == i ) {
          this.messageList[idx].selectd = true
        }else{
          this.messageList[i].selectd = false
        }
      }
      this.$forceUpdate();
    },
    handleDeleteListData(item,idx) {
      this.$confirm('确定删除好聊天好友?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.handleDeleteMessage(item,idx)
      }).catch(() => {        
      });
    },
    // 删除会话
    handleDeleteMessage(item,idx) {
      let _this = this
      let conversationID = `C2C${item.userID}`
      let promise = _this.tim.deleteConversation(conversationID);
      promise.then(function(imResponse) {
        // 删除会话成功
        const { conversationID } = imResponse.data; // 被删除的会话 ID
        _this.handlegetConversationList()
        _this.handleInitialization()
      }).catch(function(imError) {
        console.warn('deleteConversation error:', imError); // 删除会话失败的相关信息
      });
    },
    // 初始化
    handleInitialization() {
      this.messageList = []
      this.addForm = {
        content: '',
        toUserID: ''
      }
       this.toForm = {
        lgUserId: '',
      }
      this.listIdx = 0
      this.titleName = ''
      this.$store.state.listObj = {}
    },
    /**
     * 设置已读
     */
    handleDeadData(item,idx) {
      let conversationID = `C2C${item.userID}`
      let _this = this
      let promise = _this.tim.setMessageRead({conversationID: conversationID});
      promise.then(function(imResponse) {
        _this.$store.state.messageShow = false
        if(idx == '9001') {
          _this.zdHelper.statusShow = false
        }else if(idx == '9002') {
          _this.zdGuessing.statusShow = false
        }else if(idx == '9003') {
          _this.zdGame.statusShow = false
        }
        else{
          _this.$store.state.messageShow = false
          _this.listData[idx].statusShow = false

        }
        _this.$forceUpdate();
        // 已读上报成功，指定 ID 的会话的 unreadCount 属性值被置为0
      }).catch(function(imError) {
        if(idx == '9001') {
          _this.zdHelper.statusShow = true
        }else if(idx == '9002') {
          _this.zdGuessing.statusShow = true

        }else if(idx == '9003') {
          _this.zdGame.statusShow = true
        }
        else{
          _this.listData[idx].statusShow = true

        }
        // 已读上报失败
        console.warn('setMessageRead error:', imError);
      });

    },
    // 炸蛋助手
    handleZDHelperData(item,idx) {
      this.handleMessageOpen(item,idx)
    },
    // 删除
    handleDelete() {
      this.deleteShow = !this.deleteShow
    },
    /**
     * 同步资料
     */
    handleUpdateLgUserInfoToIm() {
      updateLgUserInfoToIm({lgUserId: sessionStorage.lgUserId}).then(res=>{
        if(res.code == 200) {
        }
      })

    },
    // 关闭聊天弹窗
    handleMessageingClose() {
      this.messageingShow = false
      this.$store.state.gameType = false
      window.removeEventListener('click', this.clickOther);
      this.handleInitialization()
    },
    // 打开聊天弹窗
    handleMessageingOpen(item,type) {
      this.tim = this.$store.state.tim
      this.nowTime = new Date().getTime()
      if(this.tim) {
        window.addEventListener("click", this.clickOther);
      }
      this.messageingShow = true
      if(this.$store.state.listObj) {
        this.toForm = this.$store.state.listObj
        this.addForm.toUserID = this.$store.state.listObj.lgUserId
        this.addForm.nickName = this.$store.state.listObj.nickName
        this.addForm.headImg = this.$store.state.listObj.headImg
        this.titleName = this.$store.state.listObj.nickName
      }
      this.handleUpdateLgUserInfoToIm()
      this.handleAppData(this.$store.state.listObj)
    },
    /**
     * 获取签名
     */
    handleAppData(item) {
      let _this = this
      getAppData({lgUserId: sessionStorage.lgUserId}).then(res=>{
        if(res.code == 200) {
          _this.appSDKApp = JSON.parse(decryptByDESModeCBC(res.data.data));
          if(!sessionStorage.IMShow) {
            _this.login()
            _this.handleAddGroupMember(_this.appSDKApp)
          }else{
            _this.handlegetConversationList(true)
          }
        }
      })
    },
    handleFileData(e)  {
      var file = e.target.files[0]; // js获取文件对
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
        return
      }
      let type = this.isImage(file.name);
      if (!type) {
        this.$message.error("只允许上传图片!");
        return;
      }
      this.inputType = 'text'
      this.handleImgMessageShow(file)
    },
    isImage(fileName) {
      if (typeof fileName !== "string") return;
      let name = fileName.toLowerCase();
      return (
        name.endsWith(".png") ||
        name.endsWith(".jpeg") ||
        name.endsWith(".jpg") ||
        name.endsWith(".png") ||
        name.endsWith(".bmp") ||
        name.endsWith(".gif")
      );
    }, 
    getBase64(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        let fileResult = "";
        reader.readAsDataURL(file);
        //开始转
        reader.onload = function() {
          fileResult = reader.result;
        };
        //转 失败
        reader.onerror = function(error) {
          reject(error);
        };
        //转 结束  咱就 resolve 出去
        reader.onloadend = function() {
          resolve(fileResult);
        };
      });
    },
    handleImgMessageShow(file) {
      let _this = this
      _this.addMessage = _this.tim.createImageMessage({
        to:  _this.addForm.toUserID,
        conversationType: TIM.TYPES.CONV_C2C,
        payload: {
          file:file,
        },
        onProgress: function(event) { console.log('file uploading:', event) }

        })
        _this.getBase64(file).then(res=>{
          _this.inputType = 'file'
          _this.addForm.img = res;
          _this.$nextTick(()=>{
            _this.handleMessageAdd(true)

          })
        })
    },
    // 上拉加载聊天记录
    scrollEvent(e) {
      let outerClientHeight = this.$refs.scrollBox.clientHeight; // 外层盒子可视高度
      let outerScrollTop = this.$refs.scrollBox.scrollTop; // 外层盒子卷去的高度
      let innerScrollHeight = document.querySelector("#msgText").scrollHeight; // 内层盒子全部的高度
      if ( this.$refs.scrollBox.scrollTop <= 10) {
        if(this.$refs.scrollBox.scrollTop == 0) {

        } else {
          if (!this.loading) {
            this.loading = true;
            this.loadMore();
          }

        }
        
      }
    },
    loadMore() {
      this.handleMessageListMor()
    },
    /**
     * 加载更多消息
     */
    handleMessageListMor() {
      let _this = this
      let  conversationID = _this.addForm.toUserID
      let promise = _this.tim.getMessageList({conversationID: 'C2C'+conversationID,nextReqMessageID: _this.nextReqMessageID, count: 15});
      promise.then(function(imResponse) {
        const messageList = imResponse.data.messageList; // 消息列表。
        const nextReqMessageID = imResponse.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
        _this.nextReqMessageID = nextReqMessageID
        const isCompleted = imResponse.data.isCompleted; // 表示是否已经拉完所有消息。
        _this.loading = isCompleted
        if(!isCompleted) {
          let arr = []
          let list = []
          for(let i in messageList) {
            let obj = {
              userID: messageList[i].from,
            }
            obj.headImg =messageList[i].avatar
            obj.name =  messageList[i].nick
            obj.selectd= false
            obj.itemObj= messageList[i]
            obj.isRevoked= messageList[i].isRevoked
            obj.isRevokedTime= messageList[i].time * 1000+ (60*1000 *2)
            obj.isDeleted= messageList[i].isDeleted
            if( messageList[i].payload.data) {
              let messageObj = JSON.parse(messageList[i].payload.data) 
              obj.text = messageObj.text
              let time = _this.$utils.dateFormat(messageObj.item, 'yyyy-MM-dd hh:mm:ss')
              obj.time = _this.$utils.transDate(time)
            }else{
              if(messageList[i].from == 'LinGan-Dian-Jing-ToBe-Number-One') {
                obj.headImg = require('@/assets/images/balance/first/message2.png') 
                obj.name = '炸蛋小助手'
              }else if(messageList[i].from == 'LinGan-Dian-Jing-ToBe-Number-Pwb') {

                obj.headImg = require('@/assets/images/balance/first/message3.png') 
                obj.name = '有奖竞猜'
              }else if(messageList[i].from == 'LinGan-Dian-Jing-ToBe-Number-Jcb') {

                obj.headImg = require('@/assets/images/balance/first/message1.png') 
                obj.name = '游戏陪玩'
              }
              let time
              let srtTime = messageList[i].time * 1000
              time = _this.$utils.dateFormat( srtTime,'yyyy-MM-dd hh:mm:ss') 
              obj.time = _this.$utils.transDate(time)
              if(messageList[i].payload.text)  {
                obj.text=messageList[i].payload.text

              }else{
                obj.img=messageList[i].payload.imageInfoArray[2].imageUrl
              }
            }
            if(sessionStorage.lgUserId == messageList[i].from) {
              obj.myStatus = true
            }else{
              obj.myStatus = false
            }
            arr.push(obj)
            
            list = [...arr,..._this.messageList]
          }
          _this.messageList = list
          _this.$store.state.messageList = _this.messageList
          _this.lastHeight = document.querySelector("#msgText").scrollHeight
          _this.handleMessageTop()
          if(isCompleted) {
            return
          } 

        }
      });
    },
    /**
     * 消息滚动到最底层
     */
    handleMessageTop() {
      this.$nextTick(() => {
        var msgbox = document.querySelector("#msgText");
        msgbox.scrollTop = document.querySelector("#msgText").scrollHeight-this.lastHeight- 200;
      });

    },
    /**
     * 回车键发送消息
     */
    keydown(e) {
      if(!e.shiftKey  && e.keyCode == 13 ) {
        e.cancelBubble = true // IE阻止冒泡
        e.stopPropagation(); // firefox阻止冒泡
        e.preventDefault(); // 取消事件默认动作
        this.handleTomessage() 
      }
    },
    // 发送消息
    handleMessageAdd(type) {
      let _this = this
      let obj = {}
      obj.itemObj = _this.addMessage
      // 2. 发送消息
      let promise = _this.tim.sendMessage(_this.addMessage);
      promise.then(function(imResponse) {
        // 发送成功
        obj.headImg = sessionStorage.headImg
        obj.nickName = sessionStorage.nickName
        obj.time = _this.$utils.dateFormat(new Date(), 'hh:mm')
        obj.myStatus = true
        obj.isRevoked = false
        obj.isRevokedTime = new Date().getTime() + (60*1000 *2)
        obj.isDeleted = false
        if(_this.addForm.content) {
          obj.text = _this.addForm.content
          _this.messageList.push(obj)
        }else if(_this.addForm.img){
          obj.img = _this.addForm.img
          _this.messageList.push(obj)
        }
        _this.addForm.content = ''
        _this.addForm.img = '';
        _this.handleMessageScrollTop()
        // _this.addMessage=null
      }).catch(function(imError) {
        _this.addMessage=null
        _this.addForm.content = ''
        _this.addForm.img = '';
        _this.$message.error(imError.message);
        // 发送失败
        console.warn('sendMessage error:', imError);
      });
    },
    /**
     * 或者打开页面聊天时候自动滚动到最下面一条
     */
    handleMessageScrollTop() {
      this.$nextTick(() => {
        var msgbox = document.querySelector("#msgText");
        msgbox.scrollTop = msgbox.scrollHeight;
      });
    },
    /**
     * 选择人员打开聊天
     * 获取IM人员聊天记录
     */
    handleMessageOpen(item,idx) {
      this.listIdx = idx || 9001
      if(item.userID == 'LinGan-Dian-Jing-ToBe-Number-One' || item.userID == 'LinGan-Dian-Jing-ToBe-Number-Pwb' || item.userID == 'LinGan-Dian-Jing-ToBe-Number-Jcb') {
        this.footStatus = true
      } else {
        this.footStatus = false
      }
      this.addForm.toUserID = item.userID
      this.titleName = item.nickName
      this.messageList = []
      this.loading = false
      this.innerScrollHeight = 0
      this.lastHeight = 0
      this.handleMessageListData()
      if(item) {
        this.handleDeadData(item,idx)

      }
    },
    // 获取会话消息列表
    handleMessageListData() {
      let _this = this
      _this.messageList = []
      let conversationID = _this.addForm.toUserID
      let promise = _this.tim.getMessageList({conversationID: 'C2C'+conversationID, count: 15});
      promise.then(function(imResponse) {
        const messageList = imResponse.data.messageList; // 消息列表。
        const nextReqMessageID = imResponse.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
        _this.nextReqMessageID = nextReqMessageID
        const isCompleted = imResponse.data.isCompleted; // 表示是否已经拉完所有消息。
        let time = ''
        for(let i in messageList) {
          let obj = {
            userID: messageList[i].from,
          }
          obj.headImg =messageList[i].avatar
          obj.selectd= false
          obj.itemObj= messageList[i]
          obj.isRevoked= messageList[i].isRevoked
          obj.isRevokedTime= messageList[i].time * 1000+ (60*1000 *2)
          obj.isDeleted= messageList[i].isDeleted
          obj.name =  messageList[i].nick
          if( messageList[i].payload.data) {
            let messageObj = JSON.parse(messageList[i].payload.data) 
            obj.text = messageObj.text
            let time = _this.$utils.dateFormat(messageObj.item, 'yyyy-MM-dd hh:mm:ss')
            obj.time = _this.$utils.transDate(time)
          }else{
            if(messageList[i].from == 'LinGan-Dian-Jing-ToBe-Number-One') {
              obj.headImg = require('@/assets/images/balance/first/message2.png') 
              obj.name = '炸蛋小助手'
            }else if(messageList[i].from == 'LinGan-Dian-Jing-ToBe-Number-Pwb') {
              obj.headImg = require('@/assets/images/balance/first/message3.png') 
              obj.name = '有奖竞猜'
            }else if(messageList[i].from == 'LinGan-Dian-Jing-ToBe-Number-Jcb') {
              obj.headImg = require('@/assets/images/balance/first/message1.png') 
              obj.name = '游戏陪玩'
            }else if(messageList[i].from == 'administrator') {
              return
            }
            let srtTime = messageList[i].time * 1000
            time = _this.$utils.dateFormat( srtTime,'yyyy-MM-dd hh:mm:ss') 
            obj.time = _this.$utils.transDate(time)
            if(messageList[i].payload.text)  {
              if(messageList[i].conversationID != 'C2Cadministrator'){
                obj.text=messageList[i].payload.text
                }
            }else{
              obj.img=messageList[i].payload.imageInfoArray[2].imageUrl
            }
          }
          
          if(sessionStorage.lgUserId == messageList[i].from) {
            obj.myStatus = true
          }else{
            obj.myStatus = false
          }
          _this.messageList.push(obj) 
          _this.$store.state.messageList = _this.messageList
        }
        _this.handleMessageScrollTop()
      });
    },
     /**
      * IM 登录 
      */
    login() {
      let lgUserId = sessionStorage.lgUserId
      let _this = this
      let userSig = this.appSDKApp.userSig
      let promise = this.tim.login({userID: lgUserId, userSig: userSig});
        promise.then(function(imResponse) {
          if (imResponse.data.repeatLogin === true) {
            // 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
            // _this.handleUserList()
          }else{
            sessionStorage.IMShow = true
          }
        }).catch(function(imError) {
          console.warn('login error:', imError); // 登录失败的相关信息
        });
      
    },
    /**
     * 获取临时会话列表
     */
    handlegetConversationList(type) {
      let _this = this
      let promise = _this.tim.getConversationList();
      promise.then(function(imResponse) {
        var conversationList = imResponse.data.conversationList; // 会话列表，用该列表覆盖原有的会话列表
        _this.listData = []
        let time = ''
        for(let key in conversationList) {
          let obj = {}
          if(conversationList[key].type == 'C2C') {
            if(conversationList[key].lastMessage.payload.data) {
              obj = JSON.parse(conversationList[key].lastMessage.payload.data) 
              time = _this.$utils.dateFormat(obj.item,'yyyy-MM-dd hh:mm:ss') 
              obj.showTime = _this.$utils.transDate(time) 
              obj.userID = conversationList[key].userProfile.userID
              obj.conversationID = conversationList[key].conversationID
              obj.headImg = conversationList[key].userProfile.avatar
              obj.nickName = conversationList[key].userProfile.nick
              if(conversationList[key].userID == 'C2Cadministrator'){

              }else{
                if(conversationList[key].unreadCount>0) {
                  conversationList[key].statusShow = true
                }else{
                  conversationList[key].statusShow = false
                }

              }
              _this.$forceUpdate();
            }else{
              time = _this.$utils.dateFormat( conversationList[key].lastMessage.lastTime*1000,'yyyy-MM-dd hh:mm:ss') 
              obj.showTime = _this.$utils.transDate(time) 
              obj.userID = conversationList[key].userProfile.userID
              obj.conversationID = conversationList[key].conversationID
              if(conversationList[key].userProfile.avatar) {
                obj.headImg = conversationList[key].userProfile.avatar
                obj.nickName = conversationList[key].userProfile.nick
              }else{
                if(conversationList[key].userProfile.userID =="LinGan-Dian-Jing-ToBe-Number-One") {
                  obj.headImg = require('@/assets/images/balance/first/message2.png') 
                  obj.nickName ='炸蛋助手'

                }else if(conversationList[key].userProfile.userID == 'LinGan-Dian-Jing-ToBe-Number-Pwb') {

                  obj.headImg = require('@/assets/images/balance/first/message3.png') 
                  obj.nickName = '有奖竞猜'
                }else if(conversationList[key].userProfile.userID == 'LinGan-Dian-Jing-ToBe-Number-Jcb') {

                  obj.headImg = require('@/assets/images/balance/first/message1.png') 
                  obj.nickName = '游戏陪玩'
                }else{
                  obj.headImg = _this.firstObj.headImg
                  obj.nickName =_this.firstObj.name
                }
              }
              obj.text = conversationList[key].lastMessage.messageForShow
              if(conversationList[key].unreadCount>0) {
               obj.statusShow = true
                _this.$store.state.messageShow = true
               
              }else{
                obj.statusShow = false
              }
              _this.listData.push(obj)
            }
          }else{
          }
        }
        let arr = []
        if(type) {
          if(_this.toForm.lgUserId ) {
            _this.addForm.toUserID = _this.toForm.lgUserId
            _this.addForm.nickName = _this.toForm.nickName
            _this.addForm.headImg = _this.toForm.headImg
            _this.addForm.userID = _this.toForm.lgUserId
            time = _this.$utils.dateFormat( new Date() ,'yyyy-MM-dd hh:mm:ss') 
            _this.addForm.showTime = _this.$utils.transDate(time) 
            _this.titleName = _this.addForm.nickName
            arr.push(_this.addForm)
            /**
             * 第一次加载加载更多信息  防止报错
             */
            _this.$nextTick(()=>{
              _this.handleMessageListData()

            })
          }else{
            if(_this.listData.length>0) {
              _this.addForm.toUserID = _this.listData[0].userID
              _this.addForm.nickName = _this.listData[0].nickName
              _this.titleName = _this.addForm.nickName
              _this.listIdx = 0
              _this.handleDeadData(_this.listData[0],_this.listIdx)
              _this.$nextTick(()=>{
                _this.handleMessageListData()

              })

            }
          }
        }else{
          
        }
        let list = []
        let listObj = {};
        list = list.concat(_this.listData,arr)
        let peon = list.reduce((cur,next) => {
        listObj[next.userID] ? "" : listObj[next.userID] = true && cur.push(next);
        return cur;
        },[])
        _this.listData  = peon
        _this.$forceUpdate()
        //会话列表信息解析处理，请求后台接口获取对应聊天人信息

      }).catch(function(imError) {
        console.warn('getConversationList error:', imError); // 获取会话列表失败的相关信息
      });

    },
    /**
     * 创建聊天
     */
    // 发送消息
    handleTomessage() {
      if(!this.addForm.content || !this.addForm.content.replace(/(\r\n|\n|\r)/gm,'')) {
        this.$message.error('不能发空白信息!');
        return
      }
      this.addForm.avatar = sessionStorage.headImg
      this.addForm.nick = sessionStorage.nickName
      this.addForm.item = new Date().getTime()
      this.addForm.myStatus = true
      this.handleAddMessage()
    },
    /**
     * 文本消息
     */
    handleAddMessage() {
      let _this = this
      _this.addMessage = _this.tim.createTextMessage({
        to:  _this.addForm.toUserID,
        conversationType: TIM.TYPES.CONV_C2C,
        payload: {
         text:_this.addForm.content
        },
      });
      _this.handleMessageAdd()
    },
    /**
     * 全局鼠标事件
     */
    clickOther() {
      this.nowTime = new Date().getTime()
      if(this.messageList.length>0) {
        if(this.messageIdx) {
          this.messageList[this.messageIdx].selectd = false
          this.messageIdx  = ''
        }
      }
    },
  },
  created() {
  },
  mounted() {
    this.lgUserId = sessionStorage.lgUserId
  },
  beforeDestroy() {  // 实例销毁之前对点击事件进行解绑
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
@import "./messaging.less";

</style>
