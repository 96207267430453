import http from '../fetch.js';
// 提交报名
export function getSignUp(params = {}) {
  return http({
    url: '/web/client/league/signUp',
    method: 'get',
    params: params
  });
}
// 近期赛事
export function nearCompetition(params = {}) {
  return http({
    url: '/web/client/league/nearCompetition',
    method: 'get',
    params: params
  });
}
// 赛事奖金
export function competitionAward(params = {}) {
  return http({
    url: '/web/client/league/competitionAward',
    method: 'get',
    params: params
  });
}
// 查询门店
export function selectShop(params = {}) {
  return http({
    url: '/web/client/league/selectShop',
    method: 'get',
    params: params
  });
}
// 积分排放榜
export function shopRankIngList(params = {}) {
  return http({
    url: '/web/client/league/shopRankIngList',
    method: 'get',
    params: params
  });
}
// 战绩查询

export function history(params = {}) {
  return http({
    url: '/web/client/league/record/history',
    method: 'get',
    params: params
  });
}
// 使用加倍卡
export function buyDouble(params = {}) {
  return http({
    url: '/web/client/league/buyDouble',
    method: 'get',
    params: params
  });
}
// 匹配
export function startMatching(params = {}) {
  return http({
    url: '/web/client/league/startMatching',
    method: 'get',
    params: params
  });
}
// 参加排位
export function qualifying(params = {}) {
  return http({
    url: '/web/client/league/record/qualifying',
    method: 'get',
    params: params
  });
}
// 匹配查询队员
export function selectTeamMember(params = {}) {
  return http({
    url: '/web/client/league/selectTeamMember',
    method: 'get',
    params: params
  });
}
// 签到
export function signIn(params = {}) {
  return http({
    url: '/web/client/league/signIn',
    method: 'get',
    params: params
  });
}

// 比赛详情
export function scheduleRecordInfo(params = {}) {
  return http({
    url: '/web/client/league/scheduleRecordInfo',
    method: 'get',
    params: params
  });
}
// 取消报名 
export function cancelSignUp(params = {}) {
  return http({
    url: '/web/client/league/cancelSignUp',
    method: 'get',
    params: params
  });
}
// 查询赛事图片
export function selectCaption(params = {}) {
  return http({
    url: '/web/client/league/selectCaption',
    method: 'get',
    params: params
  });
}
// 查询比赛结果数据
export function selectRecordResult(params = {}) {
  return http({
    url: '/web/client/league/selectRecordResult',
    method: 'get',
    params: params
  });
}
// 取消匹配
export function cancelMatching(params = {}) {
  return http({
    url: '/web/client/league/cancelMatching',
    method: 'get',
    params: params
  });
}


