import http from '../fetch.js';
// 导入用户id
export function importUserId(params = {}) {
    return http({
        url: '/web/client/talking/importUserId',
        method: 'get',
        params: params
    });
}
// 获取IM签名
export function getAppData(params = {}) {
  return http({
      url: '/web/client/talking/getAppData',
      method: 'get',
      params: params
  });
}

// 同步资料
export function updateLgUserInfoToIm(params = {}) {
    return http({
        url: '/web/client/talking/updateLgUserInfoToIm',
        method: 'get',
        params: params
    });
}
// 添加成员
export function addGroupMember(params = {}) {
    return http({
        url: '/web/client/talking/addGroupMember',
        method: 'get',
        params: params
    });
}
  

