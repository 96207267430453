import http from './fetch.js';

export function getMemberInfo(params = {}) {
    return http({
        url: '/web/info',
        method: 'get',
        params: params
    });
}

export function memberDeplane(params = {}) {
    return http({
        url: '/web/client/deplane',
        method: 'get',
        params: params
    });
}

export function memberBalance(params = {}) {
  return http({
      url: '/web/client/balance',
      method: 'get',
      params: params
  });
}

